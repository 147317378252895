import React from 'react'

import Img from 'gatsby-image'

import secondIcon from '../images/second-icon.svg'
import leftBg from '../images/left-bg.svg'
import rightBg from '../images/right-bg.svg'

import BgImg from './BgImg'

const Second = (props) => (
  <div style={{ position: 'relative' }}>

    <BgImg src={leftBg} isRight={false} />

    <div className="container is-margin-mobile">

      <div className="has-text-centered" style={{ paddingBottom: 50, paddingTop: 50 }}>

        <img src={secondIcon} alt={'icon'} style={{ width: 143, marginBottom: '1.5rem' }} />

        <h2 className="title is-2 ">
          To jedyny taki kurs w Polsce,
        <br />
          który jako pierwsi wprowadzamy na rynek.
      </h2>

      </div>

      <div className="columns is-flex-column-reverse-mobile">

        <div className="column is-5-tablet has-text-centered">
          <Img fluid={props.images.second} alt={'learning'}  style={{ maxWidth: 400, margin: '0 auto' }} />
        </div>

        <div className="column is-7-tablet">
          <p className="has-text-weight-semibold">
            Naszym celem NIE JEST dołożenie Ci kolejnej porcji wiedzy do nauczenia, ale wyselekcjonowanie jej i przekazanie w przyjemny sposób - za pomocą VIDEO!
        </p>

          <div>
            <p className="has-text-weight-semibold">
              Treści zawarte w kursie skomponowaliśmy w oparciu o dwa źródła:
          </p>
            <ol style={{ marginLeft: '1rem' }}>
              <li>
                wiedzę egzaminatora, który od lat sprawdza matury i przepytuje w trakcie ustnych egzaminów
            </li>
              <li>
                opinie widzów kanału „Wiedza z Wami" i naszych kursantów, którzy od czterech lat dzielą się z nami swoimi spostrzeżeniami.
            </li>
            </ol>
          </div>

          <br />

          <p className="has-text-weight-semibold">
            Dołącz do nich i już za kilka miesięcy i Ty ciesz się swoim sukcesem!
          </p>

          <p>
            Kurs jest przeznaczony dla uczniów, którzy chcą usystematyzować swoją wiedzę dotyczącą matury z j. polskiego. Jest odpowiedni dla technikum oraz by przygotować się na maturę poprawkową.
          </p>
        </div>

      </div>

    </div>

    <BgImg src={rightBg} isRight={true} />

  </div>
)

export default Second