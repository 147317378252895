import React from 'react'

import './Buy.scss'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import CTAButton from './CTAButton'
import BuyModal from './BuyModal'

const Buy = (props) => {
  const { site: { siteMetadata: { course } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata{
            course { 
              price 
              priceHigh
              priceLow
            }
          }
        }
      }
    `
  )

  const crossedPrice = course.priceHigh
  const price = props.isLowPrice ? course.priceLow : course.price

  return (
    <div id="kup-kurs" className="container buy">

      <h2 className="title is-2 has-text-info has-text-centered">Symboliczna cena</h2>
      <h6 className="title is-6 has-text-link has-text-centered">Naprawdę warto!</h6>

      <p className="has-text-centered has-text-weight-semibold">
        Przygotowując nasz kurs, chcieliśmy zadbać o wysoką jakość nagrań,  które zostały profesjonalnie zmontowane w wynajętym studio.
    </p>
      <p className="has-text-centered">
        Naszym celem jest dotarcie do jak największej liczby osób, aby realnie pokazać, że nauka może być przyjemna i prosta.
    </p>

      <div className="columns is-desktop is-flex-column-reverse-desktop has-text-centered-desktop" style={{ paddingTop: 50 }}>

        <div className="buy-img-column column" style={{ position: 'relative' }}>
          <Img
            className={'buy-img'}
            fluid={props.images.cta}
            alt={'cta'}
          />
        </div>

        <div className="column" style={{ position: 'relative' }}>

          <h5 className="title is-5 has-text-success has-text-centered-mobile has-text-weight-bold">
            Cena kursu:
          <br className="is-hidden-tablet" />
            <br className="is-hidden-tablet" />
            <br className="is-hidden-tablet" />
            <span
              className="buy-price-tag title is-6 has-text-link is-size-4"
            >
              <span style={{ textDecoration: 'line-through', color: 'rgba(0, 0, 0, 0.2)' }}>
                {crossedPrice}
              </span>
              {' '}
              <span>
                {price}
              </span>
              {' '}
              ,- zł
            </span>
          </h5>

          <br />

          <p>
            <span className="has-text-weight-semibold">Jest to cena za nasze wszystkie materiały, </span>
            które przygotują Cię zarówno do matury pisemnej, jak i ustnej. Maturę ma się raz w życiu, dlatego, naszym zdaniem, cena ta jest absolutnie nieporównywalna do tego, co otrzymasz w zamian.
        </p>
          <br />
          <p className="has-text-weight-bold">
            Dokonaj zakupu już teraz i rozpocznij naukę już za kilka minut!
        </p>


          <BuyModal isLowPrice={props.isLowPrice}>
            <CTAButton className={'buy-cta'} disabled={true} />
          </BuyModal>

        </div>

      </div>

    </div>
  )
}

export default Buy