import React from 'react'
import PropTypes from 'prop-types'

import bg from '../images/icon-bg.svg'

const flipped = {
  transform: 'scaleX(-1)',
  filter: 'FlipH'
}

const Icon = (props) => (
  <i
    style={{
      width: 79,
      height: 50,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'inline-flex',
      backgroundImage: `url(${bg})`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      ...(props.flipped ? flipped : {}),
      ...props.style
    }}
  >
    <span className="is-text has-text-weight-bold is-size-5" style={{ height: 50, lineHeight: '50px',  ...(props.flipped ? flipped : {}), }}>
      {props.children}
    </span>
  </i>
)

Icon.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
}

export default Icon