import React from 'react'

import Img from 'gatsby-image'

const Hero = (props) => (
  <div style={{
    width: '100%',
    textAlign: 'center'
  }}>
    <a href="#kup-kurs">
      <div>
        <Img
          className='is-hidden-mobile'
          style={{
            maxWidth: 1450,
            margin: '0 auto'
          }}
          fluid={props.images.hero}
          alt={'Hero image'}
        />
        <Img
          className='is-hidden-tablet'
          style={{
            marginTop: '-10%',
            width: '100%'
          }}
          fluid={props.images.heroMobile}
          alt={'Hero image'}
        />
      </div>
    </a>
  </div>
)

export default Hero