import React from 'react'

import Img from 'gatsby-image'
import { useStaticQuery } from 'gatsby'

const EndOfServiceModal = (props) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 245, height: 123) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `
  )

  return (
    <>
      <div className={`modal is-active`}>
        <div className="modal-background" style={{ backgroundColor: 'rgba(10, 10, 10, 1.6)' }}></div>
        <div className="modal-card">


          <div className="modal-card-body has-text-centered" style={{ borderRadius: 20, padding: '2rem' }}>
            <h2 className="title is-2">Kurs Powtórka Z Polskiego nie jest już dostępny w sprzedaży!</h2>

            <hr />

            <h4>
              <b>Szukasz kursu z języka polskiego?</b><br /> Odwiedź oficjalną stronę kanału Wiedza z Wami <a href={'https://wiedzazwami.com.pl'} target={'_blank'}>https://wiedzazwami.com.pl</a>
            </h4>

            <hr />

            <h4>
              <b>Chcesz nauczyć się programować?</b><br /> Zobacz kurs programowania od A do Z na <a href={'https://coderoad.pl'} target={'_blank'}>https://coderoad.pl</a>
            </h4>

          </div>
        </div>
      </div>
    </>
  )
}

EndOfServiceModal.propTypes = {}

export default EndOfServiceModal