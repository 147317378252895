import React from 'react'

import './First.scss'

import firstIcon from '../images/first-icon.svg'

import CTAButton from './CTAButton'
import BuyModal from './BuyModal'

const First = (props) => (
  <div id="o-kursie" className="container first" style={{ paddingTop: 50 }}>

    <div className="columns">

      <div className="column is-1"></div>

      <div className="column has-text-centered is-text is-size-6 is-margin-mobile">

        <img src={firstIcon} alt={'icon'} style={{ width: 143, marginBottom: '1.5rem' }} />

        <h2 className="title is-2 has-text-link">Przyszły maturzysto!</h2>
        
        <p className="has-text-weight-semibold">
          Czy jesteś już zmęczony ogromem wiedzy do opanowania przed nadchodzącymi egzaminami?
        </p>
        <p>
          Dziesiątki książek, setki stron tekstu do przeczytania i nauczenia, a do tego wiele godzin lekcji do odbycia i prace domowe do odrobienia.
        </p>
        <p className="has-text-weight-semibold">
          Czas ucieka, matura się zbliża, a Ty… już gubisz się, bo nie wiesz, za co się zabrać?
        </p>
        <p>
          Możesz już od dziś aż do dnia egzaminu zastanawiać się nad tym, co właściwie powinieneś umieć. Możesz nie przesypiać kolejnej nocy, by dokończyć następne opracowanie albo ... możesz zacząć uczyć się  SPOSOBEM!
        </p>

        <p style={{ color: '#034AB2' }} className="is-size-5 has-text-weight-semibold">
          Skorzystaj z jedynego w Polsce kursu do matury z języka polskiego,
          przygotowanego w całości przez egzaminatora CKE!
        </p>

        <p>
          Z naszym kursem powtórzysz najważniejsze rzeczy, które są Ci potrzebne, by zdać nadchodzący egzamin z satysfakcjonującym Cię wynikiem.
        </p>
        <p className="has-text-weight-semibold">
          Wiedza, którą w nim przekazujemy, służy jednemu celowi – abyś zdał/a z jak najwyższym wynikiem, zgodnie z nowym kluczem maturalnym.
        </p>
        <p>
          Wiemy, jak dużo masz jeszcze do nauczenia do innych egzaminów, dlatego tworząc kurs, zawarliśmy esencję tego, co powinieneś wiedzieć, by zdać egzamin z jak największą liczbą punktów.
        </p>

      </div>

      <div className="column is-1"></div>

    </div>

    <div className="has-text-centered">
      <BuyModal>
        <CTAButton />
      </BuyModal>
    </div>
  </div>
)

export default First