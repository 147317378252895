import React from 'react'

import Img from 'gatsby-image'

import rightBg from '../images/right-bg.svg'

import BgImg from './BgImg'

const Guarantee = (props) => (
  <div style={{ position: 'relative' }}>

    <div className="container is-margin-mobile has-text-centered-mobile">

      <div className="columns is-flex-column-reverse-mobile" style={{ paddingBottom: 50, paddingTop: 50 }}>

        <div className="column is-5-tablet">
          <Img fluid={props.images.guarantee} alt={'guarantee'} style={{ maxWidth: 400, margin: '0 auto' }}/>
        </div>

        <div className="column is-7-tablet">

          <h2 className="title is-2 has-text-info">Gwarancja zdanej matury!</h2>
          <h6 className="title is-6 has-text-link">Z Nami Ci się uda!</h6>

          <p className="has-text-weight-semibold">Jesteśmy pewni naszej metody nauczania i jakości nagrań!</p>
          <p>
            Jeśli, pomimo obejrzenia naszego kursu, nie zdasz matury z języka polskiego -
            <span className="has-text-weight-bold">ZWRÓCIMY CI PIENIĄDZE!</span>
          </p>
          <p className="has-text-weight-semibold">
            Możesz więc tylko zyskać, nic nie mogąc stracić!
          </p>
          <p>
            Zdecydowaliśmy się wprowadzić tę gwarancję, ponieważ jesteśmy całkowicie pewni wiedzy, którą oferujemy.
          </p>
        </div>

      </div>

    </div>

    <BgImg src={rightBg} isRight={true} />

  </div>
)

export default Guarantee