import React from 'react'

import featureIcon1 from '../images/feature-1.svg'
import featureIcon2 from '../images/feature-2.svg'
import featureIcon3 from '../images/feature-3.svg'
import featureIcon4 from '../images/feature-4.svg'

import Feature from './Feature'

const Features = (props) => (
  <div className="container is-margin-mobile">

    <div className="has-text-centered" style={{ paddingBottom: 50, paddingTop: 50 }}>

      <h2 className="title is-2">Co jeszcze otrzymasz?</h2>
      <h6 className="title has-text-link is-6">... to i tamto :)</h6>

    </div>

    <div className="columns">

      <div className="column">
        <Feature
          icon={featureIcon1}
          header={<span>BONUS<br />Jak pozbyć się stresu?</span>}
          content={<p>Wiemy, że matura ustna może być dla Ciebie bardzo stresująca. Dlatego poza samymi lekcjami z Panią Izabelą, poprosiliśmy profesjonalnego aktora i reżysera, by podzielił się swoimi sekretami, z pomocą których pozbędziesz się całkowicie stresu i zrobisz dobre wrażenie na egzaminatorach. W prawie półgodzinnym nagraniu dowiesz się o niuansach związanych z Twoją mową ciała oraz technikach, które pozwolą Ci się zrelaksować. Musisz tylko zastosować poznaną wiedzę w praktyce.</p>}
        />
      </div>
      <div className="column">
        <Feature
          icon={featureIcon2}
          header={<span>Symulacja matury ustnej:</span>}
          content={<p>Będziesz wiedział, jak proces matury ustnej przebiega, ponieważ nagraliśmy nawet jej symulację. Zobaczysz, jak w praktyce wygląda wywołanie ucznia do odpowiedzi, przygotowanie i rozmowa. Wszystko po to, byś po obejrzeniu tego nagrania, nie był zestresowany nowym doświadczeniem. W końcu już raz je przeżyłeś, wyobrażając sobie tę sytuację w Twojej w głowie na podstawie naszego filmu. :)</p>}
        />
      </div>

    </div>

    <div className="columns is-3">

      <div className="column">
        <Feature
          icon={featureIcon3}
          header={<span>Odpowiednia dieta<br />przed egzaminem:</span>}
          content={<p>Mówi się, że jesteś tym, co jesz. Jest w tym bardzo dużo racji,, dlatego przed egzaminem, warto stosować kilka kluczowych zasad, by Twój mózg pracował sprawniej. Nagraliśmy specjalny krótki materiał z najważniejszymi zasadami, których powinieneś przestrzegać, by podczas egzaminu być w jak najlepszej formie.</p>}
        />
      </div>
      <div className="column">
        <Feature
          icon={featureIcon4}
          header={<span>Odpowiedni ubiór:</span>}
          content={<p>Jak Cię widzą, tak Cię piszą. Ubiór to jedna z ważniejszych rzeczy podczas Twojej matury. Jeśli dobrze dobierzesz swoje ubrania, będziesz nie tylko bardziej pewny siebie, ale także lepiej postrzegany przez egzaminatora. Czując się dobrze, Twoja głowa również będzie znacznie lepiej pracować. Jako dodatkowy bonus poznasz opinię Izabeli odnośnie różnego typu ubioru egzaminowanych uczniów.</p>}
        />
      </div>

    </div>

    <br/>
    <br/>

  </div >
)

export default Features