import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import First from "../components/First"
import WhatsInside from "../components/WhatsInside"
import Second from "../components/Second"
import Features from "../components/Features"
import Testimonials from "../components/Testimonials"
import Guarantee from "../components/Guarantee"
import Buy from "../components/Buy"
import Author from "../components/Author"
import FAQ from "../components/FAQ"
import EndOfServiceModal from "../components/EndOfServiceModal"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <Hero images={{
        hero: data.hero.childImageSharp.fluid,
        heroMobile: data.heroMobile.childImageSharp.fluid,
      }} />
      <First />
      <WhatsInside images={{ bg: data.whatsInside.childImageSharp.fluid }} />
      <Second images={{ second: data.second.childImageSharp.fluid }}/>
      <Features />
      <Testimonials 
        images={{
          bg: data.testimonials.childImageSharp.fluid,
          testimonials1: data.testimonials1.childImageSharp.fixed,
          testimonials2: data.testimonials2.childImageSharp.fixed,
          testimonials3: data.testimonials3.childImageSharp.fixed,
        }}
      />
      <Guarantee images={{guarantee: data.guarantee.childImageSharp.fluid}} />
      <Buy images={{cta: data.cta.childImageSharp.fluid}}/>
      <Author images={{author: data.author.childImageSharp.fluid}}/>
      <FAQ />
      <EndOfServiceModal />
    </Layout>
  )
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment fluidImageSVGPrev on File {
    childImageSharp {
      fluid(maxWidth: 1500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment fluidImageSmall on File {
    childImageSharp {
      fluid(maxWidth: 450, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero.png" }) {
      ...fluidImageSVGPrev
    }
    heroMobile: file(relativePath: { eq: "hero-mobile.png" }) {
      ...fluidImageSVGPrev
    }
    whatsInside: file(relativePath: { eq: "whats-inside-bg.png" }) {
      ...fluidImage
    }
    second: file(relativePath: { eq: "second.png" }) {
      ...fluidImageSmall
    }
    testimonials: file(relativePath: { eq: "testimonials-bg.png" }) {
      ...fluidImage
    }
    testimonials1: file(relativePath: { eq: "karol.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    testimonials2: file(relativePath: { eq: "kuba.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    testimonials3: file(relativePath: { eq: "ola.jpg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    guarantee: file(relativePath: { eq: "guarantee.png" }) {
      ...fluidImageSmall
    }
    cta: file(relativePath: { eq: "cta.png" }) {
      ...fluidImageSmall
    }
    author: file(relativePath: { eq: "author.png" }) {
      ...fluidImageSmall
    }
  }
`