import React from 'react'

import Img from 'gatsby-image'

import leftBg from '../images/left-bg.svg'

import BgImg from './BgImg'

const Author = (props) => (
  <div style={{ position: 'relative' }}>

    <BgImg src={leftBg} isRight={false} />

    <div className="container has-text-centered-mobile is-margin-mobile" style={{ paddingBottom: 50, paddingTop: 50 }}>

      <h2 className="title is-2 has-text-info is-hidden-tablet">O autorze</h2>

      <div className="columns is-flex-column-reverse-mobile">

        <div className="column is-7-tablet has-text-right  has-text-centered-mobile">

          <h2 className="title is-2 has-text-info is-hidden-mobile">O autorze</h2>
          <h5 className="title is-5 has-text-success">Izabela Kasperek</h5>

          <p>
            Nauczycielka i egzaminatorka Centralnej Komisji Egzaminacyjnej.
          <br />
            Jest znana ze swojego kanału na YouTube „Wiedza z Wami", na którym dzieli się swoją wiedzą na temat języka polskiego już od 2014 roku.
          <br />
            Subskrybuje go ponad 100.000 osób. Jej filmy były wyświetlone łącznie ponad 12 milionów razy.
        </p>

        </div>

        <div className="column is-5-tablet">
          <Img fluid={props.images.author} alt={'author'} style={{ maxWidth: 400, margin: '0 auto' }} />
        </div>

      </div>

    </div>

  </div>
)

export default Author