import React from 'react'

import Carousel from 'nuka-carousel'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import dot from '../images/dot.svg'
import dotFilled from '../images/dot-filled.svg'

const Controls = ({ controlsDown, currentSlide, slideCount, goToSlide }) => {
  const positionStyles = controlsDown ?
    {
      flexDirection: 'row',
      top: 48,
    }
    :
    {
      flexDirection: 'column',
      left: 48,
    }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ...positionStyles
      }}
    >
      {
        Array(slideCount).fill(1).map((el, i) => (
          <img
            key={i}
            style={{ width: 16, height: 16, margin: 16, cursor: 'pointer' }}
            src={currentSlide === i ? dotFilled : dot}
            alt="slide-control"
            onClick={() => goToSlide(i)}
          />
        ))
      }
    </div>
  )
}

const Testimonial = (props) => (
  <div
    style={{
      margin: '1rem',
    }}
  >
    <div style={{
      padding: '2rem',
      border: '2px solid white',
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Img fixed={props.avatar} alt="avatar" style={{ border: '2px solid white', borderRadius: '50%', margin: '1rem', padding: '5px', height: 100 }} />
      <h6 className="title is-6 has-text-info">{props.name}</h6>
      <p className="has-text-weight-semibold">
        {props.content}
      </p>
    </div>
  </div >
)

const TestimonialsCarousel = (props) => (
  <Carousel
    wrapAround={true}
    heightMode={'first'}
    renderCenterRightControls={props.controlsDown ? null : Controls}
    renderCenterLeftControls={null}
    renderTopCenterControls={null}
    renderBottomCenterControls={props.controlsDown ? (props) => <Controls {...props} controlsDown={true} /> : null}
    {...props}
  >
    <Testimonial
      avatar={props.images.testimonials1}
      name={'Karol Chojnacki'}
      content={'Język polski od dawna sprawiał mi trudności. Jeszcze miesiąc przed egzaminem, nie miałem pewności czy zostanę do niego dopuszczony. Egzaminy próbne oblałem, natomiast ostateczny oficjalny wynik, jaki osiągnąłem to 100% z matury ustnej, oraz powyżej 80% z matury pisemnej. Osobą, która mi w tym pomogła, była właśnie Pani Izabela, którą poznałem poprzez jej kanał w serwisie YouTube 3 lata temu. Szczerze polecam i dziękuję za otrzymaną pomoc! :)'}
    />
    <Testimonial
      avatar={props.images.testimonials2}
      name={'Jakub'}
      content={'W tym roku zdaję maturę. Bardzo się stresuję, że sprawi mi wiele trudności. Przeszukiwałem cały internet i szukałem odpowiedniego źródła, z którego będzie mi się dobrze uczyć. Kurs Izabeli jest fantastyczny. Tłumaczy wszystko w prosty sposób. Teraz jestem spokojny o wynik mojej matury.'} />
    <Testimonial
      avatar={props.images.testimonials3}
      name={'Aleksandra Kosowska'}
      content={'Zdałam maturę rok temu, język polski nigdy nie sprawiał mi ogromnych trudności, jednak przed maturą zaczęłam się stresować, szczególnie częścią ustną. Dzięki filmom Pani Izabeli, jestem bardzo zadowolona z wyniku matury z języka polskiego zarówno w części pisemnej jak i ustnej. Wobec tego dostałam się na wymarzone studia, gdzie język polski naturalnie był brany pod uwagę 😀 Pozdrawia studentka prawa✋'}
    />
  </Carousel>
)

const Testimonials = (props) => {
  return (
    <BackgroundImage
      id="opinie"
      fluid={props.images.bg}
      style={{
        paddingTop: 75,
        paddingBottom: 75,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container  has-text-centered">

        <h2 className="title has-text-white is-2 has-text-info">Opinie</h2>
        <h6 className="title has-text-white is-6">Zaufali nam inni, zaufaj i Ty!</h6>

        <div className="columns has-text-white">

          <div className="column is-hidden-tablet" style={{ padding: '2rem' }}>
            <TestimonialsCarousel
              images={props.images}
              controlsDown={true}
              width={'100%'}
            />
          </div>

          <TestimonialsCarousel
            images={props.images}
            className={'is-hidden-mobile'}
            style={{ marginLeft: '10%' }}
            width={'80%'}
          />

        </div>

      </div>

    </BackgroundImage>
  )
}

export default Testimonials