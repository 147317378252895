import React from 'react'

import BackgroundImage from 'gatsby-background-image'
import Icon from './Icon'

const WhatsInside = (props) => (
  <BackgroundImage
    fluid={props.images.bg}
    style={{
      paddingTop: 75,
      paddingBottom: 75,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}
  >
    <div className="container has-text-centered is-margin-mobile">

      <h2 className="title has-text-white is-2">Co znajdziesz w kursie?</h2>
      <h6 className="title has-text-link is-6">To i owo, a także ...</h6>

      <div className="columns has-text-white">

        <div className="column">
          <div style={{ padding: '2rem' }}>
            <Icon>1</Icon>
          </div>
          <div>
            <p>
              Nasz kurs powstał w oparciu o wiedzę stricte praktyczną. Nie wahamy się i pokazujemy jak dosłownie NAPISAĆ rozprawkę, jak ODPOWIADAĆ podczas egzaminu ustnego.
            </p>
          </div>
        </div>
        <div className="column">
          <div style={{ padding: '2rem' }}>
            <Icon flipped>2</Icon>
          </div>
          <div>
            <p>
              Znajdziesz w nim wszystko – począwszy od zagadnień związanych z maturą pisemną, poprzez wiedzę niezbędną na maturze ustnej aż do praktycznych porad, związanych z każdym etapem Twojej maturalnej ścieżki.             </p>
          </div>
        </div>
        <div className="column">
          <div style={{ padding: '2rem' }}>
            <Icon>3</Icon>
          </div>
          <div>
            <p>
              Dodatkowo otrzymasz także konspekty najważniejszych lektur i innych tekstów kultury, takich jak popularne filmy i książki, które z pewnością znasz i do których również możesz odwoływać się na maturze. :)             </p>
          </div>
        </div>
        <div className="column">
          <div style={{ padding: '2rem' }}>
            <Icon flipped>4</Icon>
          </div>
          <div>
            <p>
              Wszystkie treści przygotowała Pani Izabela Kasperek, która od lat sprawdza matury, zarówno pisemne jak i ustne. Jest egzaminatorem Centralnej Komisji Egzaminacyjnej.            </p>
          </div>
        </div>

      </div>

    </div>

  </BackgroundImage>
)

export default WhatsInside