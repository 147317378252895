import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import CTAButton from './CTAButton'

function buildLink(course, price) {
  return (
    'https://secure.tpay.com?' +
    'id=' + course.sellerId +
    '&kwota=' + price +
    '&opis=' + course.description +
    '&opis_sprzed=powtorkazpolskiego.pl' +
    '&crc=' + encodeURIComponent(course.id || null) +
    '&return_url=' + course.returnURL
  )
}

const BuyModal = (props) => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  const { site: { siteMetadata: { course } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata{
            course { 
              price 
              priceLow
              sellerId
              description
              id
              returnURL
            }
          }
        }
      }
    `
  )

  const price = props.isLowPrice ? course.priceLow : course.price

  return (
    <>
      {
        React.cloneElement(
          props.children,
        )
      }
      <div className={`modal ${isOpen ? 'is-active' : ''}`}>
        <div onClick={close} className="modal-background"></div>
        <div className="modal-card">
          <div className="modal-card-body has-text-centered" style={{ borderRadius: 20, padding: '2rem' }}>
            <h2 className="title is-2">Zaraz zostaniesz przekierowany na stronę płatności online!</h2>

            <p>
              Uważnie wpisz swój adres e-mail na stronie płatności - na ten adres otrzymasz dostęp do kursu!
            </p>

            <p>
              Kupując kurs akceptujesz <a target="_blank" href="/regulamin.pdf" rel="noopener noreferrer">regulamin</a>
              <span> i </span>
              <a target="_blank" href="/polityka.pdf" rel="noopener noreferrer">politykę prywatności</a>
            </p>

            <CTAButton
              label={'Dalej!'}
              style={{ margin: 0, marginTop: '1rem' }}
              href={buildLink(course, price)}
            />

          </div>
          <button
            onClick={close}
            className="modal-close is-large"
          ></button>
        </div>
      </div>
    </>
  )
}

BuyModal.propTypes = {}

export default BuyModal